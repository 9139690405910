import Vue from 'vue'
import vueRouter from 'vue-router'
import pathArr from "@/router/pathArr";

// 导入需要的组件
import Login from "@/components/MyLogin";
import Home from "@/components/MyHome";

import users from "@/components/menus/MyUsers";
import rights from "@/components/menus/MyRights";
import goods from "@/components/menus/MyGoods";
import orders from "@/components/menus/MyOrders";
import settings from "@/components/menus/MySettings";
import userinfo from "@/components/user/MyUserDetail";

Vue.use(vueRouter)

const router = new vueRouter({
    routes:[
        { path: '/', redirect: '/login' },
        { path: '/login', component: Login },
        { path: '/home', component: Home, redirect: '/home/users', children: [
                { path: 'users', component: users },
                { path: 'rights', component: rights},
                { path: 'goods', component: goods},
                { path: 'orders', component: orders},
                { path: 'settings', component: settings},

                // 用户详情页的路由规则
                { path: 'userinfo/:id', component: userinfo, props: true}
            ]}
    ]
})

router.beforeEach(function (to,from,next) {
    if (pathArr.indexOf(to.path) !== -1) {
        const token = localStorage.getItem('token')
        if (token) {
            next()
        } else {
            next('/login')
        }
    } else {
        next()
    }
})

export default router
