<template>
  <h4 class="text-center">订单管理</h4>
</template>

<script>
export default {
  name: 'MyOrders',
}
</script>

<style lang="less" scoped>
</style>