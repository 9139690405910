<template>
  <h4 class="text-center">权限管理</h4>
</template>

<script>
export default {
  name: 'MyRights',
}
</script>

<style lang="less" scoped>
</style>