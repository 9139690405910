<template>
  <h4 class="text-center">系统设置</h4>
</template>

<script>
export default {
  name: 'MySettings',
}
</script>

<style lang="less" scoped>
</style>