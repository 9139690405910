<template>
  <h4 class="text-center">商品管理</h4>
</template>

<script>
export default {
  name: 'MyGoods',
}
</script>

<style lang="less" scoped>
</style>