import { render, staticRenderFns } from "./MyLogin.vue?vue&type=template&id=6b16a85e&scoped=true&"
import script from "./MyLogin.vue?vue&type=script&lang=js&"
export * from "./MyLogin.vue?vue&type=script&lang=js&"
import style0 from "./MyLogin.vue?vue&type=style&index=0&id=6b16a85e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b16a85e",
  null
  
)

export default component.exports